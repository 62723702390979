<template>
  <div>

    <validation-observer
      tag="div"
      v-for="(item, itemIndex) in vItems"
      :ref="`${slotConfig.name}_${itemIndex}`"
      :vid="`${slotConfig.name}_${itemIndex}`"
      :key="item.id"
      class="mb-05"
    >
      <b-row>
        <b-col cols="12" class="d-flex">
          <div class="flex-grow-1 d-flex">
            <b-input-group :id="`${slotConfig.name}_${itemIndex}_itemText`">
              <b-form-input
                type="text"
                :value="vItemsText[itemIndex]"
                :class="slotConfig.input_class"
                style="height:100%;background-color:transparent;"
                v-b-modal="`${slotConfig.name}_${itemIndex}_modal`"
                readonly
              />
              <b-input-group-append>
                <b-button
                  v-b-modal="`${slotConfig.name}_${itemIndex}_modal`"
                  variant="primary"
                  style="height:100%;"
                >編輯
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
          <b-button
            v-if="slotConfig.show_del_btn"
            class="flex-shrink-0 ml-1"
            variant="secondary"
            @click="deleteItem(itemIndex)"
            :disabled="vItems.length <= slotConfig.min_count"
          >
            {{ $t('common.delete') }}
          </b-button>
        </b-col>

        <!-- 檢測當前 item 是否符合規則 -->
        <validation-provider
          tag="div"
          #default="{ errors }"
          :ref="`${slotConfig.name}_${itemIndex}_main`"
          :vid="`${slotConfig.name}_${itemIndex}_main`"
          :name="$t(slotConfig.label)"
          :customMessages="slotConfig.custom_messages"
          :rules="slotConfig.rule"
        >
          <b-col
            cols="12"
            v-show="errors.length !== 0 && (!slotConfig.check_duplicate || !duplicateItems.includes(vItemsText[itemIndex]))"
          >
            <input type="hidden" :value="item.value.id && item.value.modelTag ? item.value.id : ''"/>
            <small class="text-danger">{{ errors[0] }}</small>
          </b-col>
        </validation-provider>

        <!-- 檢測當前 item 與其他 items 之間是否存在重複 -->
        <validation-provider
          tag="div"
          :ref="`${slotConfig.name}_${itemIndex}_sub`"
          #default="{ errors }"
          :vid="`${slotConfig.name}_${itemIndex}_sub`"
          :name="$t(slotConfig.label)"
          :customMessages="{ length: slotConfig.duplicate_tips}"
          rules="length:0"
          v-if="slotConfig.check_duplicate"
        >
          <b-col
            cols="12"
            v-show="errors.length !== 0 && duplicateItems.includes(vItemsText[itemIndex])"
          >
            <input type="hidden" :value="duplicateItems.includes(vItemsText[itemIndex]) ? '_' : ''"/>
            <small class="text-danger">{{ errors[0] }}</small>
          </b-col>
        </validation-provider>

      </b-row>


      <b-modal
        :id="`${slotConfig.name}_${itemIndex}_modal`"
        title="編輯 固定外設"
        size="lg"
        :ok-title="$t('common.confirm')"
        centered no-fade no-close-on-backdrop ok-only static
      >
        <b-row>
          <b-col cols="12" md="4" lg="3">
            <label class="field-row-label" :for="`${slotConfig.name}_${itemIndex}_modelTag`">
              型號標識 <span style="color: red">*</span>
            </label>
          </b-col>
          <b-col cols="12" md="8" lg="9">
            <v-select
              :id="`${slotConfig.name}_${itemIndex}_modelTag`"
              label="text"
              :options="modelTagOptions"
              v-model="item.value.modelTag"
              :reduce="text => text.value"
              :clearable="false"
              :searchable="false"
              class="mb-1"
              @input="onModelTagChange(itemIndex, arguments[0])"
            >
              <template #no-options>{{ $t('common.no_options') }}</template>
            </v-select>
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col cols="12" md="4" lg="3">
            <label class="field-row-label" :for="`${slotConfig.name}_${itemIndex}_purposes`">
              類型 <span style="color: red">*</span>
            </label>
          </b-col>
          <b-col
            cols="12" md="8" lg="9"
            class="d-flex justify-content-start flex-wrap"
            style="padding-top: calc(0.438rem + 1px); padding-bottom: calc(0.438rem + 1px); column-gap: 22px; row-gap: 12px;"
          >
            <template v-for="(purpose, _i) in purposes">
              <b-form-checkbox
                :id="`${slotConfig.name}_${itemIndex}_purposes`"
                :checked="modelList.find(model => model.modelTag === item.value.modelTag) ? modelList.find(model => model.modelTag === item.value.modelTag).purposes.includes(purpose.value) : false"
                type="text" disabled
              >
                {{ purpose.text }}
              </b-form-checkbox>
            </template>
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col cols="12" md="4" lg="3">
            <label class="field-row-label" :for="`${slotConfig.name}_${itemIndex}_name`">名稱</label>
          </b-col>
          <b-col cols="12" md="8" lg="9">
            <b-form-input
              :id="`${slotConfig.name}_${itemIndex}_name`"
              :value="modelList.find(model => model.modelTag === item.value.modelTag) ? modelList.find(model => model.modelTag === item.value.modelTag).name : ''"
              type="text" disabled
            />
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col cols="12" md="4" lg="3">
            <label class="field-row-label" :for="`${slotConfig.name}_${itemIndex}_brand`">品牌</label>
          </b-col>
          <b-col cols="12" md="8" lg="9">
            <b-form-input
              :id="`${slotConfig.name}_${itemIndex}_brand`"
              :value="modelList.find(model => model.modelTag === item.value.modelTag) ? modelList.find(model => model.modelTag === item.value.modelTag).brand : ''"
              type="text" disabled
            />
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col cols="12" md="4" lg="3">
            <label class="field-row-label" :for="`${slotConfig.name}_${itemIndex}_model`">型號</label>
          </b-col>
          <b-col cols="12" md="8" lg="9">
            <b-form-input
              :id="`${slotConfig.name}_${itemIndex}_model`"
              :value="modelList.find(model => model.modelTag === item.value.modelTag) ? modelList.find(model => model.modelTag === item.value.modelTag).model : ''"
              type="text" disabled
            />
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col cols="12" md="4" lg="3">
            <label class="field-row-label" :for="`${slotConfig.name}_${itemIndex}_remark`">型號備註</label>
          </b-col>
          <b-col cols="12" md="8" lg="9">
            <b-form-input
              :id="`${slotConfig.name}_${itemIndex}_remark`"
              :value="modelList.find(model => model.modelTag === item.value.modelTag) ? modelList.find(model => model.modelTag === item.value.modelTag).remark : ''"
              type="text" disabled
            />
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col cols="12" md="4" lg="3">
            <label class="field-row-label" :for="`${slotConfig.name}_${itemIndex}_id`">
              ID <span style="color: red">*</span>
            </label>
          </b-col>
          <b-col cols="12" md="8" lg="9">
            <b-form-input
              :id="`${slotConfig.name}_${itemIndex}_id`"
              :list="`${slotConfig.name}_${itemIndex}_idList`"
              :disabled="item.value.modelTag === ''"
              v-model="item.value.id"
              type="text"
            />
            <datalist :id="`${slotConfig.name}_${itemIndex}_idList`">
              <option
                v-for="opt in historicalIds.filter(opt => opt.bleName === item.value.modelTag)"
                :label="opt.bleID" :value="opt.bleID"
              ></option>
            </datalist>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="4" lg="3">
            <label class="field-row-label" :for="`${slotConfig.name}_${itemIndex}_idRemark`">ID 備註</label>
          </b-col>
          <b-col cols="12" md="8" lg="9">
            <b-form-input
              :id="`${slotConfig.name}_${itemIndex}_idRemark`"
              :disabled="item.value.modelTag === ''"
              v-model="item.value.idRemark"
              type="text"
            />
          </b-col>
        </b-row>
      </b-modal>

    </validation-observer>

    <b-button
      v-if="slotConfig.show_add_btn" variant="primary" @click="addItem()"
      :disabled="vItems.length >= slotConfig.max_count"
    >
      {{ $t('common.add') }}
    </b-button>

  </div>
</template>

<script>
import common from '@/common'
import kioskUtils from '@/libs/kiosk-utils'
import _ from 'lodash'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import '@core/utils/validations/validations'
import { getDuplicateItems } from '@/libs/ez-utils'

export default {
  name: 'FieldModalFixedPeripherals',
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    input_class: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
    rootItem: {
      type: Object,
      default: () => ({}),
    },
    modelList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      autoIndex: 0,
      vItems: [], // 存放 QRCode 對象
      vItemDefaultValue: { // modelItem 的空值
        modelTag: '',
        id: '',
      },
      slotConfig: Object.assign({
        // type: 'text',
        name: 'defaultName',
        label: 'defaultLabel',
        min_count: this.rootItem.rule?.split('|')
          .includes('required') ? 1 : 0,
        max_count: 1,
        check_duplicate: true,
        duplicate_tips: this.$t('common.duplicate_content_tips'),
        show_del_btn: false,
        show_add_btn: false,
      }, this.rootItem.slot_config),
      purposes: kioskUtils.purposes,
      historicalIds: []
    }
  },

  computed: {
    vItemsText() {
      return this.vItems.map(item => {
        return item.value.modelTag ? `${item.value.modelTag} | ${item.value.id}` : ''
      })
    },
    modelTagOptions: {
      get() {
        return common.getSelectOptions(this.modelList.filter(m => m.modelTag !== ''), 'modelTag', 'modelTag')
      }
    },
    duplicateItems: {
      get() {
        return this.slotConfig.check_duplicate
          ? getDuplicateItems(this.vItemsText.filter(text => text !== ''))
          : []
      },
    },
  },

  watch: {
    items: {
      handler(items, _oldValues) {
        // 當 items 的值發生變化時，會先刪除多餘的 modelItems，再新增缺少的 modelItems
        for (const [i, item] of items.entries()) {
          while (this.vItems.length >= i + 1 && !_.isEqual(item, this.vItems[i].value)) {
            this.deleteItem(i, false)
          }
          if (this.vItems.length < i + 1) {
            this.addItem(_.clone(item))
          }
        }
      },
      deep: true,
    },
  },

  created() {
    common.apiGetData(common.getTableUrl(
      '/kiosk/WeHealth/GetHistoricalDevices',
      undefined, {}, {
        pageIndex: 0,
        pageSize: 999
      }))
      .then(({ data }) => this.historicalIds = _.uniqBy(data.items, 'bleID'))
  },

  methods: {
    addItem(value, isUpdate = true) {
      this.vItems.push({
        value: value === undefined ? _.clone(this.vItemDefaultValue) : value,
        id: this.autoIndex,
      })
      this.autoIndex += 1
      isUpdate && this.updateValues()
    },
    deleteItem(itemIndex, isUpdate = true) {
      this.vItems.splice(itemIndex, 1)
      isUpdate && this.updateValues()
    },

    onModelTagChange(itemIndex, modelTag) {
      this.vItems[itemIndex].value.id = ''
      this.vItems[itemIndex].value.idRemark = ''
      // console.log(itemIndex, this.vItems[itemIndex].value)
    },

    updateValues(itemIndex) {
      this.$emit('updateValues', this.vItems.map(item => item.value))
    },
  },
}
</script>

<style lang="scss" scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}

.field-row-label {
  padding: calc(0.438rem + 1px) 0;
}
</style>
