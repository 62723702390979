import common from '@/common'
import kioskUtils from '@/libs/kiosk-utils'

export function reloadFieldData() {
}

export default [
  {
    name: 'kioskToken',
    label: '設備 Token',
    type: 'text',
    rule: 'required',
    edit_disable: true,
  },
  {
    name: 'kioskName',
    label: '設備名稱',
    type: 'text',
    rule: 'required',
    edit_disable: true,
  },
  {
    name: 'companyName',
    label: '公司',
    type: 'text',
    rule: 'required',
    edit_disable: true,
  },
  {
    name: 'facilityName',
    label: '院舍',
    type: 'text',
    rule: 'required',
    edit_disable: true,
  },
  {
    name: 'peripheralMode',
    label: '外設模式',
    type: 'select',
    rule: 'required',
    options: common.getSelectOptions([
      { name: '固定', value: 'Fixed' },
      { name: '自由', value: 'Free'},
    ], 'name', 'value'),
  },
  {
    name: 'fixedPeripherals',
    label: '固定外設',
    type: 'slot',
    slot: 'fixedPeripherals',
    slot_config: {
      name: 'fixedPeripherals',
      label: '固定外設',
      // type: 'file',
      // file_accept: '.png,.jpg,.jpeg,.jfif',
      // placeholder: common.getI18n('kiosk.placeholder').replace('{_type_}', '.png, .jpg, .jpeg, .jfif'),
      rule: 'required',
      max_count: 15,
      show_del_btn: true,
      show_add_btn: true,
    },
  },
  {
    name: 'fixedPurposes',
    label: '固定外設類型（合計）',
    type: 'checkboxFlow',
    options: kioskUtils.purposes,
    edit_disable: true,
    add_disable: true,
  },
]
